import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

const FooterContainer = styled.footer`
  text-align: left;
  color: ${props => props.theme.colors.grey.dark};
  margin-top: 4em;
  padding-left: ${props => props.theme.layout.paddingLeft};
  padding-right: ${props => props.theme.layout.paddingRight};
  padding-top: 1.5rem;
  height: 10rem;
  background-color: ${props => props.theme.colors.grey.light};
  font-family: ${props => props.theme.fonts.family};
  font-style: normal;
  font-weight: ${props => props.theme.fonts.weight};
  font-size: ${props => props.theme.fonts.sizes.footer};
`

const List = styled.ul`
list-style-type: none;
padding: 0;
margin: 0;
  `

const ListItem = styled.li`
  text-decoration: none;
  `

  const FooterLink = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.colors.grey.dark};
`

const Footer = () => (
  <FooterContainer>
    <List>
    <ListItem><FooterLink to="#">Sporttherapie</FooterLink></ListItem>
    <ListItem><FooterLink to="/about">Über Mich</FooterLink></ListItem>
    <ListItem><FooterLink to="/terminbuchung">Termin Buchen</FooterLink></ListItem>
    <ListItem><FooterLink to="/contact">Kontakt</FooterLink></ListItem>
    </List>
    <p>© Sibylle Sporttherapie 2020</p>
  </FooterContainer>
)

export default Footer
