import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { useTranslation } from "react-i18next"

const NavItem = styled(Link)`
  text-decoration: none;
  color: #000;
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  transition: all 200ms ease-in;
  position: relative;

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: ".";
    color: transparent;
    height: 1px;
    transition: all 0.4s ease-in;
  }

  :hover {
    ::after {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    padding: 20px 0;
    font-size: 1.5rem;
    z-index: 20;
  }
`
const MenuLinks = () => {
  const { t } = useTranslation()

  return (
    <>
      <NavItem to="/about">{t('menu.about')}</NavItem>
      <NavItem to="/terminbuchung">{t('menu.booking')}</NavItem>
      <NavItem to="/contact">{t('menu.contact')}</NavItem>
    </>
  )
}

export default MenuLinks