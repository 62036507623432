/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"

import Header from "./header"
import Footer from "./footer"

import styled, { ThemeProvider } from "styled-components";

import { withTrans } from "../i18n/withTrans"

const theme = {
  colors: {
    primary: "#ff6019",
    grey: {
      light: "#f5f5f7",
      dark: "#7a7a7b"
    },
    dark: "#000000",
    light: "#ffffff",
    lightTranslucent: "rgba(255, 255, 255, 0.9)",
    debug: "#ebf3ff"
  },
  fonts: {
    family: "ff-real-headline-pro, sans-serif",
    weight: 300,
    sizes: {
      body: "1em",
      footer: "15px"
    }
  },
  layout: {
    paddingLeft: "23px",
    paddingRight: "23px"
  }
};

const Container = styled.div`
  background-color: ${props => props.theme.colors.light};
  font-family: ${props => props.theme.fonts.family};
  font-style: normal;
  font-weight: ${props => props.theme.fonts.weight};
  font-size: ${props => props.theme.fonts.sizes.body};
  padding-left: ${props => props.theme.layout.paddingLeft};
  padding-right: ${props => props.theme.layout.paddingRight};
  max-width: 600px;
  margin: 0 auto;
`;

const Layout = ({ pageTitle, children, t, i18n }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <ThemeProvider theme={theme}>
      <SEO title={pageTitle} keywords={[`sibylle eckert`, `sporttherapie`, `therapie`, `physiotherapie`, `zurich`, `switzerland`]} />
      <Header siteTitle={data.site.siteMetadata.title} />
      <Container>
        <main>{children}</main>
      </Container>
      <Footer />
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default withTrans(Layout)
